import { graphql } from '../../generated/gql';

export const querySearchDrawerPurchaseOrders = graphql(/* GraphQL */ `
  query searchDrawerPurchaseOrders($customerId: ID!, $sort: [SearchSortCriteria!]) {
    searchPurchaseOrdersByCustomerId(
      page: 0
      size: 1000
      customerId: $customerId
      criteria: []
      sort: $sort
      macros: []
    ) {
      totalRecords
      pos {
        id
        poNumber
      }
    }
  }
`);
