export const sessionStorageCache = {
  get(key: string) {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  remove(key: string) {
    sessionStorage.removeItem(key);
  },

  // Optional
  allKeys() {
    return Object.keys(sessionStorage);
  },
};
