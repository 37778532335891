import { Crumb } from '@beacon-devops/components';
import { Link, useLocation } from 'react-router-dom';
import { CrumbWithPermission } from '@beacon-types/crumbWithPermission';
import { useCurrentUserFromContext } from '@features/auth/user/currentUser/context';

function toTitleCase(str: string) {
  return str.replace(/\b\w+/g, (s: string) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase());
}

export function useCrumbsFilteredByPermissions(crumbs?: CrumbWithPermission[]) {
  let location = useLocation();
  const { permissions } = useCurrentUserFromContext();

  let filteredCrumbs: Crumb[] = [];
  if (crumbs) {
    filteredCrumbs = crumbs.filter((crumb) => {
      if (!crumb.requiredPermission) {
        return true;
      }
      return permissions[crumb.requiredPermission];
    });

    if (filteredCrumbs.length === 1) {
      // if there is just one crumb, do not show it as it shows the current page
      filteredCrumbs = [];
    }
  }

  const pathnames = location
    ? location.pathname.split('/').reduce((acc, pathName) => {
        if (pathName) {
          return [
            ...acc,
            {
              name: toTitleCase(pathName),
              as: Link,
              to: '/',
            },
          ];
        }
        return acc;
      }, [])
    : [];

  return filteredCrumbs || pathnames;
}
