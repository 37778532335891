import { AvailableFreeTrackingCarriersQuery } from '@services/LiveBoardsService/generated/graphql';
import { useLiveBoardQuery } from 'api';
import { availableFreeTrackingCarriersQuery } from '../graphql/availableFreeTrackingCarriersQuery';

const AVAILABLE_FREE_TRACKING_CARRIERS_QUERY_KEY = 'availableFreeTrackingCarriers';

type Args<T> = {
  select?: (data: AvailableFreeTrackingCarriersQuery) => T;
  enabled?: boolean;
};

export function useAvailableFreeTrackingCarriersApi<T>({ select, enabled }: Args<T>) {
  return useLiveBoardQuery(
    [AVAILABLE_FREE_TRACKING_CARRIERS_QUERY_KEY],
    async (_, gqlClient) => gqlClient.request(availableFreeTrackingCarriersQuery),
    { select, enabled },
  );
}
