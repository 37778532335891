import { Auth0Provider } from '@auth0/auth0-react';
import { theme } from '@beacon-devops/components';
import 'app/base.css';
import 'app/cord-custom.scss';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { TooltipProvider } from '@components/common/RCTooltip';
import { isLocal } from '@utils/env';
import { FeatureFlagProvider } from 'app/thirdPartyTools/featureFlags/components/FeatureFlagProvider';
import { sessionStorageCache } from './sessionStorageCache';
import { queryClient } from '../queryClient';

export function Root() {
  return (
    <Auth0Provider
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      cache={sessionStorageCache}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <FeatureFlagProvider>
          <ThemeProvider theme={theme}>
            <TooltipProvider>
              <Outlet />
            </TooltipProvider>
          </ThemeProvider>
        </FeatureFlagProvider>
        {isLocal && <ReactQueryDevtools initialIsOpen={false} position="bottom" />}
      </QueryClientProvider>
    </Auth0Provider>
  );
}
