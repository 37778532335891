import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { CargoStatus } from '@services/LiveBoardsService/generated/graphql';
import { generateSort } from '@services/ShipmentDataGatewayService/queries/cargo/utils/generateSort';
import { useLiveBoardQuery } from 'api';
import { LIVE_BOARDS_QUERY_KEY, CARGOS_QUERY_KEY } from 'constants/sharedKeys';
import { boardCargoRefetchInterval } from './boardCargoRefetchInterval';
import { searchCargosQuery } from '../graphql/searchCargos';
import { BoardCargosWithTimeline, searchCargosWithTimelineQuery } from '../graphql/searchCargosWithTimeline';

interface useLiveBoardSearchCargosArgs<T> {
  page?: number;
  size?: number;
  nestedSearchTerm?: string;
  externalBoardId?: string;
  sort?: string;
  select?: (cargos: BoardCargosWithTimeline) => T;
  enabled?: boolean;
  isFreeTrackingBoard?: boolean;
  includeTimeline: boolean;
}

export const BoardCargosBaseQueryKey = [LIVE_BOARDS_QUERY_KEY, CARGOS_QUERY_KEY];

export const useLiveBoardSearchCargosApi = <T>({
  page = 0,
  size = 20,
  nestedSearchTerm,
  sort,
  externalBoardId = '',
  select,
  isFreeTrackingBoard,
  includeTimeline,
  enabled,
}: useLiveBoardSearchCargosArgs<T>) =>
  useLiveBoardQuery(
    [...BoardCargosBaseQueryKey, page, size, sort, nestedSearchTerm, externalBoardId],
    async (_, gqlClient) =>
      gqlClient
        .request(includeTimeline ? searchCargosWithTimelineQuery : searchCargosQuery, {
          page: page > 0 ? page - 1 : 0,
          nestedSearchTerm,
          size,
          sort: generateSort(sort),
          externalBoardId,
        })
        .then((data) => data.searchCargos),
    {
      placeholderData: isFreeTrackingBoard ? keepPreviousData : undefined,
      refetchIntervalInBackground: true,
      refetchInterval: (query) => {
        const allCargosProcessing = query.state.data
          ? query.state.data.cargos.every((cargo) => cargo.status === CargoStatus.Unspecified)
          : false;
        return boardCargoRefetchInterval(query.state.dataUpdateCount, allCargosProcessing, isFreeTrackingBoard);
      },

      retry: false,
      select,
      enabled,
    },
  );

export function useInvalidateLiveBoardSearchCargosQuery(page?: number) {
  const queryClient = useQueryClient();
  let queryKey: (number | string)[] = BoardCargosBaseQueryKey;
  if (page) {
    queryKey.push(page);
  }
  return () =>
    queryClient.invalidateQueries({
      queryKey,
    });
}
