import { useAuth0 } from '@auth0/auth0-react';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { commonGraphqlResponseMiddleware } from '../graphql/middleware/commonGraphqlResponseMiddleware';
import { ResponseMiddleWare } from '../graphql/types';

export function useGraphqlQueryWithToken<TQueryKey extends QueryKey, TQueryFnData, TError, TData = TQueryFnData>(
  endpoint: string,
  queryKey: TQueryKey,
  fetcher: (params: TQueryKey, gqlClient: GraphQLClient) => Promise<TQueryFnData>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
  middleware?: ResponseMiddleWare,
) {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  return useQuery({
    queryKey,
    queryFn: async () => {
      let token;
      try {
        if (isAuthenticated && !isLoading) {
          token = await getAccessTokenSilently();
        }
      } catch (e) {
        token = '';
      }

      const responseMiddleware = middleware || commonGraphqlResponseMiddleware;

      const gqlClient = new GraphQLClient(endpoint, {
        headers: { authorization: `Bearer ${token}` },
        responseMiddleware,
      });

      return fetcher(queryKey, gqlClient);
    },
    ...options,
  });
}
