import { graphql } from '../../../../../generated/gql';

export const aggregatedFilterDataQuery = graphql(/* GraphQL */ `
  query aggregatedFilterDataV2($request: AggregatedFilterDataRequest!) {
    aggregatedFilterDataV2(request: $request) {
      labels {
        id
        value
      }
      carriers {
        id
        value
      }
      suppliers {
        id
        value
      }
      buyers {
        id
        value
      }
      vehicles {
        id
        value
      }
      flightNumbers {
        id
        value
      }
      originLocations {
        id
        value
        country
        portCode
      }
      orderOriginLocations {
        id
        value
        country
        portCode
      }
      primaryLoadLocations {
        id
        value
        country
        portCode
      }
      transshipmentLocations {
        id
        value
        country
        portCode
      }
      primaryDischargeLocations {
        id
        value
        country
        portCode
      }
      destinationLocations {
        id
        value
        country
        portCode
      }
      orderDestinationLocations {
        id
        value
        country
        portCode
      }
      warehouseLocations {
        id
        value
      }
      forwarderNames {
        id
        value
      }
    }
  }
`);
