import { graphql } from '../../generated/gql';

export const searchPurchaseOrdersListQuery = graphql(`
  query searchPurchaseOrdersList($page: Int! = 0, $size: Int! = 20, $customerId: ID!, $criteria: [SearchCriteria!]) {
    searchPurchaseOrdersByCustomerId(
      page: $page
      size: $size
      customerId: $customerId
      criteria: $criteria
      macros: []
      sort: []
    ) {
      page
      pageSize
      totalPages
      totalRecords
      pos {
        id
        poNumber
      }
    }
  }
`);
