import { graphql } from '../../../../../generated/gql';

export const cargosQuery = graphql(/* GraphQL */ `
  query cargo($id: ID!) {
    cargo(id: $id) {
      id
      errors {
        type
      }
      customFields {
        key
        value
      }
      carrierShipmentId
      status
      placeholder
      modeOfTracking
      freshnessTimestamp
      mode
      carrier {
        displayName
        nmftaCode
      }
      documents {
        id
        associatedId
        sizeBytes
        type
        name
        createdAt
        createdBy
        createdByUserId
        sizeBytes
      }
      createdAt
      createdByName
      labels {
        name
      }
      slimPurchaseOrders {
        poNumber
        id
        supplierName
      }
      shipmentReferences {
        id
        type
        value
        purchaseOrderId
      }
      container {
        containerNumber
      }
      demurrageInfo {
        daysOnQuayPoD
        daysAtTsPort
        daysOffQuayDest
      }
      isWatched
      transportSummary {
        legs
        currentLeg
        haulagePairExists
        currentMode
        currentVehicle {
          displayName
          identification {
            imoNumber
          }
        }
        prePrimaryLoad {
          ...TransportSummaryLocation
        }
        origin {
          ...TransportSummaryLocation
        }
        primaryLoad {
          ...TransportSummaryLocation
        }
        transshipments {
          ...TransportSummaryLocation
        }
        primaryDischarge {
          ...TransportSummaryLocation
        }
        postPrimaryDischarge {
          ...TransportSummaryLocation
        }
        destination {
          ...TransportSummaryLocation
        }
        warehouse {
          ...TransportSummaryLocation
        }
      }
      timeline {
        legs {
          location {
            name
            unLocation {
              code
              countryName
            }
          }
          locationLabel
          onwardTransportInfo {
            mode
            vehicle {
              displayName
            }
            isCurrent
          }
          isFinalDestination
          milestones {
            order
            label
            hasBeenAchieved
            otherDateInfoLabel
            otherDateInfo {
              lastFetched
              eventDateTime {
                date
                timestamp
                zone
              }
              sourceName
            }
            dateInfo {
              eventDateTime {
                date
                timestamp
                zone
              }
              sourceName
              lastFetched
            }
          }
        }
      }
    }
  }
`);
