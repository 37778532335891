import { useAuth0 } from '@auth0/auth0-react';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { commonGraphqlResponseMiddleware } from '../graphql/middleware/commonGraphqlResponseMiddleware';
import { ResponseMiddleWare } from '../graphql/types';

export function useGraphqlMutationWithToken<TMutationFnData, TError, TVariables, TData = TMutationFnData>(
  endpoint: string,
  mutationFn: (variables: TVariables, gqlClient: GraphQLClient) => Promise<TData>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationKey' | 'mutationFn'>,
  /**
   * The default middleware logs everything in sentry, allowing middleware to be null
   * gives abiity to individual API to not log to sentry the expected errors
   *
   * If middleware is not set, it is undefined, in that case sentry logging is enabled
   * If middlware is set to null, sentry logging is disabled
   * If middleware is set to a customMiddleware, it is left to customMiddleware to log to sentry or not
   */
  middleware?: ResponseMiddleWare | null,
  delaySuccess: number = 0,
) {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  return useMutation({
    mutationFn: async (variables: TVariables) => {
      let token;
      try {
        if (isAuthenticated && !isLoading) {
          token = await getAccessTokenSilently();
        }
      } catch (e) {
        token = '';
      }

      const responseMiddleware = middleware !== null ? middleware || commonGraphqlResponseMiddleware : undefined;

      const gqlClient = new GraphQLClient(endpoint, {
        headers: { authorization: `Bearer ${token}` },
        responseMiddleware,
      });

      const result = await mutationFn(variables, gqlClient);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(result);
        }, delaySuccess);
      });
    },
    ...options,
  });
}
