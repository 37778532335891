import { Mode } from '@services/ShipmentDataGatewayService/generated/graphql';

export enum ContainerOrPurchaseOrderView {
  CONTAINERS = 'containers',
  PURCHASE_ORDERS = 'purchaseOrders',
}

export interface TrackingPageContextType {
  mode?: Mode;
  containerOrPurchaseOrderView?: string;
  page: number;
  pageSize: number;
  searchKeyword: string;
  filters: string[];
  notStatuses: string[];
  portOfDischarge?: string[];
  portOfLoading?: string[];
  origin?: string[];
  destination?: string[];
  warehouses?: string[];
  carriers?: string[];
  forwarders?: string[];
  transhipmentPorts?: string[];
  labels?: string[];
  vessels?: string[];
  relativeDate?: string;
  startDate?: string;
  endDate?: string;
  milestone?: string;
  cargoReadyDate?: string;
  etaChangedFilter: boolean;
  watchlistFilter: boolean;
  placeholderFilter: boolean;
  demurrageFilter: boolean;
  sort: string;
  numberOfItemsFound?: number;
  routingDirect?: boolean;
  routingIndirect?: boolean;
  suppliers?: string[];
  buyers?: string[];
  orderOrigin?: string[];
  orderDestination?: string[];
  demurrageValue: number;
  daysAtTsPort: number;
  isDaysAtTsPortOn: boolean;
  flightNumber: string[];
  daysOffQuayFilter: boolean;
  daysOffQuayValue: number;
  setContainerOrPurchaseOrderView: (p: string) => void;
  setPage: (p: number) => void;
  setPageSize: (p: number) => void;
  setPageAndSize: (p: number, s: number) => void;
  setSearchKeyword: (p: string) => void;
  setFilters: (p: string[]) => void;
  setPortOfDischarge: (p: string[], isGroupChange?: boolean) => void;
  setPortOfLoading: (p: string[], isGroupChange?: boolean) => void;
  setOrigin: (p: string[], isGroupChange?: boolean) => void;
  setDestination: (p: string[], isGroupChange?: boolean) => void;
  setWarehouses: (p: string[]) => void;
  setCarriers: (p: string[]) => void;
  setForwarders: (p: string[]) => void;
  setTranshipmentPorts: (p: string[], isGroupChange?: boolean) => void;
  setVessels: (p: string[]) => void;
  setRelativeDate: (p: string) => void;
  setStartDate: (p: string) => void;
  setEndDate: (p: string) => void;
  setMilestone: (p: string) => void;
  setCargoReadyDate: (p: string) => void;
  setSort: (p: string) => void;
  setNumberOfItemsFound: (p: false | number | undefined) => void;
  setRoutingDirect: (p: boolean) => void;
  setRoutingIndirect: (p: boolean) => void;
  setEtaChangedFilter: (p: boolean) => void;
  setWatchlistFilter: (p: boolean) => void;
  setSuppliers: (p: string[]) => void;
  setBuyers: (p: string[]) => void;
  setOrderOrigin: (p: string[]) => void;
  setOrderDestination: (p: string[]) => void;
  setPlaceholderFilter: (p: boolean) => void;
  setLabels: (p: string[]) => void;
  userView: string;
  setUserView: (b: string) => void;
  setDaysAtTsPort: (isOn: boolean, value: number, sort: string) => void;
  setMode: (mode: Mode, filters: string[]) => void;
  setDisplayMode: (mode: Mode) => void;
  displayMode: Mode | undefined;
  setDaysOnQuay: (isOn: boolean, value: number) => void;
  setDaysOffQuay: (isOn: boolean, value: number) => void;
  setFlightNumber: (p: string[]) => void;
}
