import { useSdgMutation } from 'api';
import { graphql } from '../../../generated/gql';

const deleteNonTrackedOceanCargoMutation = graphql(/* GraphQL */ `
  mutation deleteNonTrackedOceanCargo($cargoId: ID!) {
    deleteNonTrackedOceanCargo(cargoId: $cargoId)
  }
`);

export interface DeleteNonTrackedOceanCargoMutationArgs {
  cargoId: string;
}

export const useDeleteNonTrackedOceanCargoMutation = () => {
  return useSdgMutation((args: DeleteNonTrackedOceanCargoMutationArgs, client) => {
    return client.request(deleteNonTrackedOceanCargoMutation, { cargoId: args.cargoId });
  });
};
