import { graphql } from '../../../generated/gql';

export const searchCargosForMapQuery = graphql(/* GraphQL */ `
  query searchCargosForMap(
    $page: Int! = 0
    $size: Int! = 20
    $criteria: [SearchCriteria!]
    $macros: [GqlMacro!]
    $sort: [SearchSortCriteria!]
  ) {
    searchCargos(page: $page, size: $size, criteria: $criteria, macros: $macros, sort: $sort) {
      page
      pageSize
      totalPages
      totalRecords
      cargos {
        id
        status
        freshnessTimestamp
        container {
          containerNumber
        }
        createdAt
        carrier {
          displayName
          nmftaCode
        }
        slimPurchaseOrders {
          poNumber
          id
          supplierName
        }
        isWatched
        transportSummary {
          primaryLoad {
            location {
              name
              type
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
            }
          }
          primaryDischarge {
            location {
              name
              type
              geoLocation {
                lat
                lon
              }
              unLocation {
                code
              }
            }
            milestoneViews {
              full {
                type
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                }
              }
            }
          }
          currentVehicle {
            displayName
            identification {
              imoNumber
            }
          }
          legs
          currentLeg
          primaryLegs
          currentPrimaryLeg
        }
        milestoneViews {
          po {
            type
            dateInfo {
              eventDateTime {
                date
                timestamp
                zone
              }
            }
          }
        }
      }
    }
  }
`);
