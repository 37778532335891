import { FreeTrackingBoardQuery } from '@services/LiveBoardsService/generated/graphql';
import { useLiveBoardQuery } from 'api';
import { freeTrackingBoardInfoByBoardIdQuery } from '../graphql/freeTrackingBoardByBoardIdQuery';

export const FREE_TRACKING_BOARD_INFO_QUERY_KEY = 'freeTrackingBoards';

type Args<T> = {
  select?: (data: FreeTrackingBoardQuery) => T;
  boardId: string;
  enabled?: boolean;
};

export function useFreeTrackingBoardInfoByBoardIdApi<T>({ select, boardId, enabled = true }: Args<T>) {
  return useLiveBoardQuery(
    [FREE_TRACKING_BOARD_INFO_QUERY_KEY],
    async (_, gqlClient) => gqlClient.request(freeTrackingBoardInfoByBoardIdQuery, { boardId }),
    { select, enabled },
  );
}
