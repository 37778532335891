import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getNewFeatureFlagClient } from '@services/FeatureFlagsService';
import { FeatureFlagContext } from '../context/FeatureFlagContext';
import { IsFeatureEnabled } from '../types';
import { getIsFeatureEnabled } from '../utils';

export function FeatureFlagProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const { isLoading: isLoadingAuth, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [isFeatureEnabled, setIsFeatureEnabled] = useState<IsFeatureEnabled>(getIsFeatureEnabled());
  const [loadingIsFeatureEnabled, setLoadingIsFeatureEnabled] = useState(true);

  useEffect(() => {
    async function setupFeatureFlags(isLoggedIn: boolean) {
      const beaconAuthToken = isLoggedIn ? await getAccessTokenSilently() : undefined;
      const featureFlagClient = await getNewFeatureFlagClient(beaconAuthToken);
      featureFlagClient.on('ready', () => {
        const isFeatureEnabled = getIsFeatureEnabled(featureFlagClient);
        setIsFeatureEnabled(isFeatureEnabled);
        setLoadingIsFeatureEnabled(false);
        // We cannot stop the client in Ready callback, so we need to use an immediate setTimeout
        setTimeout(() => {
          featureFlagClient.stop();
        }, 0);
      });
      featureFlagClient.start();
    }

    if (!isLoadingAuth && loadingIsFeatureEnabled) {
      setupFeatureFlags(isAuthenticated);
    }
  }, [isLoadingAuth, isAuthenticated, getAccessTokenSilently, loadingIsFeatureEnabled]);

  const memoizedFeatureEnabled = useMemo(
    () => ({ isFeatureEnabled, isLoading: loadingIsFeatureEnabled }),
    [JSON.stringify(isFeatureEnabled), loadingIsFeatureEnabled],
  );

  return <FeatureFlagContext.Provider value={memoizedFeatureEnabled}>{children}</FeatureFlagContext.Provider>;
}
