import { userPermissionsMock } from './permissionsFixtures';
import { UserPermissions } from '../userPermissions';

// Auth0 config - https://gitlab.com/beacon-devops/auth0-config/-/blob/master/auth0/tenant.yaml?ref_type=heads

export const leastPermissionFixture = userPermissionsMock;

export const limitedPlanUserPermissionsFixture: UserPermissions = {
  ...userPermissionsMock,
  canReadShipmentTracking: true,
};

export const starterPlanUserPermissionsFixture: UserPermissions = {
  ...limitedPlanUserPermissionsFixture,
  canReadShipmentTracking: true,
  canRequestShipmentTracking: true,
  canReadAddressBook: true,
  canWriteAddressBook: true,
  canDeleteShipmentTracking: true,
  canReadBoard: true,
};

export const businessPlanUserPermissionsFixture: UserPermissions = {
  ...starterPlanUserPermissionsFixture,
  canViewTrackingHome: true,
  canReadShipmentTracking: true,
  canReadLabels: true,
  canWriteLabels: true,
  canReadPO: true,
  canReadWatchlist: true,
  canReadDaysOnQuay: true,
  canViewTableView: true,
  canWriteToWatchlist: true,
  canExportCsv: true,
  canReadAddressBook: true,
  canWriteAddressBook: true,
  canReadHaulageTime: true,
  canWriteHaulageTime: true,
  canReadDocuments: true,
  canReadDocumentCount: true,
  canReadDaysOffQuay: true,
  canReadFreightRates: true,
  canReadOceanPointToPointSchedules: true,
  canRequestOceanShipmentTrackingWithoutCarrier: true,
  canReadCustomFields: true,
  canWriteCustomFields: true,
  canReadOceanEmissionsReport: true,
  canReadOceanVesselGeolocationData: true,
  canReadAirTransitTimeReport: true,
  canReadOceanTransitTimeReport: true,
  canReadAirVolumeReport: true,
  canReadOceanVolumeReport: true,
  canReadOceanRoutesReport: true,
  canReadRoadVolumeReport: true,
  canReadRoadTransitTimeReport: true,
};

export const proPlanUserPermissionsFixture: UserPermissions = {
  ...businessPlanUserPermissionsFixture,
  canReadHistoricalShipments: true,
};

export const adminPermissionsFixture: UserPermissions = {
  ...starterPlanUserPermissionsFixture,
  canWriteCustomer: true,
};

export const starterPlanWithVolumeReportAddonUserPermissionsFixture: UserPermissions = {
  ...starterPlanUserPermissionsFixture,
  canReadAirVolumeReport: true,
  canReadOceanVolumeReport: true,
};

export const proPlanWithTrpAddonUserPermissionsFixture: UserPermissions = {
  ...proPlanUserPermissionsFixture,
  canReadHistoricalWeatherData: true,
};

export const viewBoardOnlyPermissionFixture: UserPermissions = {
  ...userPermissionsMock,
  canViewBoardsOnly: true,
};

export const writeOrderBoardsPermissionFixture: UserPermissions = {
  ...businessPlanUserPermissionsFixture,
  canWriteOrderBoards: true,
};

export const ordersAddonPermissionFixture: Partial<UserPermissions> = {
  canWriteOrderBoards: true,
};
