import { TrackingPageContextType } from '@components/tracking/types';
import { Mode } from '../../../generated/graphql';

export const MacrosKeys = {
  arrivalDeparturetimeframe: 'filter_cargo_arrival_departure_timeframe',
  arrivalDepartureRelativeTimeframe: 'filter_cargo_arrival_departure_relative_timeframe',
  cargoReadyDateTimeframe: 'filter_cargo_ready_date_timeframe',
  cargoReadyDateRelativeTimeframe: 'filter_cargo_ready_date_relative_timeframe',
  cargoSearchTerm: 'cargo_search_term',
  etaChangesTimeframe: 'eta_changes_time_frame',
  liveShipments: 'live_shipments_filter',
};

export type SearchMacrosFields = Partial<
  Pick<
    TrackingPageContextType,
    'searchKeyword' | 'milestone' | 'relativeDate' | 'startDate' | 'endDate' | 'etaChangedFilter' | 'cargoReadyDate'
  >
> & { liveShipments?: boolean };

export type SearchCriteriaFields = Partial<
  Pick<
    TrackingPageContextType,
    | 'labels'
    | 'filters'
    | 'notStatuses'
    | 'demurrageFilter'
    | 'demurrageValue'
    | 'watchlistFilter'
    | 'origin'
    | 'portOfLoading'
    | 'transhipmentPorts'
    | 'portOfDischarge'
    | 'destination'
    | 'warehouses'
    | 'carriers'
    | 'vessels'
    | 'suppliers'
    | 'buyers'
    | 'orderOrigin'
    | 'orderDestination'
    | 'placeholderFilter'
    | 'forwarders'
    | 'isDaysAtTsPortOn'
    | 'daysAtTsPort'
    | 'routingDirect'
    | 'routingIndirect'
    | 'flightNumber'
    | 'daysOffQuayFilter'
    | 'daysOffQuayValue'
  > & { mode?: `${Mode}`; poIds?: string[]; canViewOCH?: boolean }
>;

export const BolProcessingStatusText = 'Awaiting container assignment';
