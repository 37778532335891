import { UseMutationOptions } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { useGraphqlMutationWithToken } from './useGraphqlMutationWithToken';
import { SDG_ENDPOINT } from '../constants';

export function useSdgMutation<TMutationFnData, TError, TVariables, TData = TMutationFnData>(
  mutationFn: (variables: TVariables, gqlClient: GraphQLClient) => Promise<TData>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
  delaySuccess?: number,
) {
  return useGraphqlMutationWithToken(SDG_ENDPOINT, mutationFn, options, undefined, delaySuccess);
}
